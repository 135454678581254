import { Avatar, AvatarFallback, AvatarImage } from "../../../core/components/ui/avatar"
import { cn } from "../../../core/lib/utils"
import icon from '../../../assets/icons/icon.png'
import floderIcon from '../../../assets/images/Others/Folder.png'
import { useSelector } from "react-redux"
import { RootState } from "../../../redux-store/stores/store"
import { Badge } from "../../../core/components/ui/badge"
import { BookCheck, Copy, CopyCheck, File, Folder, FolderOpen, Speech, StopCircle } from "lucide-react"
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Button } from "../../../core/components/ui/button"
import FileIconViewer from "../../../shared/components/file-icon-viewer/file-icon-viewer"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../../../core/components/ui/hover-card"
import remarkMath from "remark-math"
import rehypeKatex from "rehype-katex"
import { useEffect, useState } from "react"

const LinkRenderer: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ href, children, ...props }) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
            {children}
        </a>
    );
};


export const ChatBubble = (props: any) => {

    const { userInfo } = useSelector((state: RootState) => state.auth)
    const [playText, setPlayText] = useState(false)
    const [textCoppied, setTextCoppied] = useState(false)

    const commandList = [{
        code: "file-only",
        value: "File only:"
    }, {
        code: "web-only",
        value: "Web only:"
    }, {
        code: "web-also",
        value: "Web also:"
    }, {
        code: "fo",
        value: "File only:"
    }, {
        code: "wo",
        value: "Web only:"
    }, {
        code: "wa",
        value: "Web also:"
    }];

    const replaceTextWithDiv = (value: string): string => {
        const escapedCommands = commandList.map(cmd => ({
            code: cmd.code.replace(/[\/\\^$*+?.()|[\]{}]/g, '\\$&'),
            value: cmd.value
        }));
        const regex = new RegExp(`(^|\\.\\s+)(${escapedCommands.map(cmd => `/${cmd.code}`).join('|')})(\\s|$)`, 'g');
        if (value) {
            value = value.replace(regex
                , (match, p1, p2) => {
                    const foundCommand = escapedCommands.find(cmd => `/${cmd.code}` === p2);
                    return `${foundCommand ? `***\`${foundCommand.value}\`*** ` : ''}`;
                }
            )
        }
        return value
    }

    const handleContentPlay = (content: string, status: boolean) => {
        let utterance = new SpeechSynthesisUtterance(content);
        if (status) {
            speechSynthesis.speak(utterance);
            setPlayText(true);
        }
        if (!status) {
            speechSynthesis.cancel();
            setPlayText(false);
        }

    }

    const copyTextToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setTextCoppied(true)
            setTimeout(() => {
                setTextCoppied(false)
            }, 2000)

        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }


    useEffect(() => {
        const checkSpeakingStatus = () => {
            const speaking = window.speechSynthesis.speaking;
            setPlayText(speaking);
        };

        const interval = setInterval(checkSpeakingStatus, 100);

        return () => clearInterval(interval);
    }, [])


    return (
        <div className="flex flex-row space-x-4 md:space-x-8  w-full max-w-full">
            {!props.isUser &&
                <div className="">
                    <Avatar className="size-8 border-2 border-primary/60 bg-card items-center justify-center">
                        <span>
                            <AvatarImage src={icon} alt="Avatar" />
                            <AvatarFallback>SF</AvatarFallback>
                        </span>
                    </Avatar>
                </div>
            }
            <div className={cn("flex flex-col max-w-[75%] items-center justify-start", props.isUser ? "ml-auto bg-primary text-primary-foreground px-4 py-2 rounded-l-2xl rounded-br-2xl" : "bg-card px-4 py-2 rounded-bl-2xl rounded-r-2xl  w-full")}>

                {
                    props.references && props.references.length > 0 &&
                    <div className="flex flex-col py-4 space-y-2 w-full bg-card rounded-2xl">
                        <Badge className="w-fit px-3 rounded-xl space-x-2">
                            <File className="size-4" />
                            <p>Sources</p>
                        </Badge>
                        <div className="flex flex-col space-y-3 h-full w-full py-2">
                            {props.references.map((_item: any, index: number) =>
                            (

                                <div key={`reference-${index}`} className="text-wrapp-2 rounded-2xl bg-muted space-x-2 flex flex-col md:flex-row w-full justify-between h-full shadow-md py-2 px-4  hover:cursor-pointer">
                                    <div className="flex flex-col md:flex-row w-full md:max-w-[80%] h-fit gap-4" onClick={() => { window.open(_item.fileUrl, '_blank'); }}>
                                        <div className="flex flex-row md:flex-col w-full md:w-fit md:space-y-2 h-fit justify-between">
                                            <div className="flex flex-col w-fit space-y-2 h-fit max-w-[75px]">
                                                <div className="flex-shrink-0 w-[50px] h-[50px] shadow-lg rounded-xl">
                                                    <FileIconViewer
                                                        className="w-full h-full"
                                                        file={_item}
                                                    />
                                                </div>

                                            </div>
                                            <div className="flex md:hidden flex-row items-start md:items-center justify-end md:justify-center space-x-2">
                                                <Button size={"icon"} variant="outline" className="rounded-full" onClick={(e) => { e.preventDefault(); e.stopPropagation(); window.open(_item.folderUrl, '_blank'); }}><Folder className="size-4" /></Button>
                                                {/* <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button variant="ghost" className="h-8 w-8 p-0">
                                                        <span className="sr-only">Open menu</span>
                                                        <DotsHorizontalIcon className="h-4 w-4" />
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="end">
                                                    <DropdownMenuItem onClick={() => { }}>
                                                        Search from this {_item.type === 1 ? 'folder' : 'file'}
                                                    </DropdownMenuItem>
                                                </DropdownMenuContent>
                                            </DropdownMenu> */}
                                            </div>
                                        </div>
                                        <div className="flex flex-col flex-shrink-0 space-y-1 w-full">
                                            <p className="text-md  font-bold  break-words">{_item.fileName.split('.')[0].replace(/_/g, ' ')}</p>
                                            {_item.integrationName &&
                                                <Badge className="w-fit h-fit text-xs font-thin italic rounded-xl">{_item.integrationName}</Badge>}
                                            {/* <p className="text-xs italic">{_item.filePath}</p> */}
                                            {_item.chunkContent &&
                                                <HoverCard>
                                                    <HoverCardTrigger asChild>
                                                        <span className="text-ellipsis overflow-hidden text-sm ">{_item.chunkContent.split('', 300)}</span>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="w-80 h-full">
                                                        <div className="flex flex-col w-full justify-between space-x-4">
                                                            <div className="flex items-center pt-2 w-full">
                                                                <p className="text-sm w-full break-words">{_item.chunkContent}</p>
                                                            </div>
                                                        </div>
                                                    </HoverCardContent>
                                                </HoverCard>
                                            }
                                        </div>
                                    </div>
                                    <div className="hidden md:flex flex-row items-center justify-end md:justify-center space-x-2">
                                        <Button size={"icon"} variant="outline" className="rounded-full" onClick={(e) => { e.preventDefault(); e.stopPropagation(); window.open(_item.folderUrl, '_blank'); }}><Folder className="size-4" /></Button>
                                        {/* <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button variant="ghost" className="h-8 w-8 p-0">
                                                        <span className="sr-only">Open menu</span>
                                                        <DotsHorizontalIcon className="h-4 w-4" />
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="end">
                                                    <DropdownMenuItem onClick={() => { }}>
                                                        Search from this {_item.type === 1 ? 'folder' : 'file'}
                                                    </DropdownMenuItem>
                                                </DropdownMenuContent>
                                            </DropdownMenu> */}
                                    </div>
                                </div>
                            )
                            )}
                        </div>
                    </div>
                }

                {
                    props.sources && props.sources.length > 0 &&
                    <div className="flex flex-col py-4 space-y-2 w-full bg-card rounded-2xl">
                        <Badge className="w-fit px-3 rounded-xl space-x-2">
                            <FolderOpen className="size-4" />
                            <p>Directories</p>
                        </Badge>
                        <div className="flex flex-row flex-wrap gap-3 h-full w-full py-2">
                            {props.sources.map((_item: any, index: number) =>
                            (

                                <div key={`reference-${index}`} className="text-wrapp rounded-2xl bg-muted space-x-2 flex flex-row w-full md:w-4/12 h-fit shadow-md py-2 px-4  hover:cursor-pointer"

                                >   <div className="flex flex-row w-full h-fit gap-4 items-center justify-center" onClick={() => { window.open(_item.directory, '_blank'); }}>

                                        <div className="flex-shrink-0 w-[50px] h-[50px] shadow-lg rounded-xl items-center flex flex-col justify-center">
                                            <img alt="Folder Icon" src={floderIcon}/>
                                        </div>
                                        <div className="flex flex-col space-y-1 w-full">
                                            <p className="text-sm font-semibold  break-words">{_item.directory.split('/')[_item.directory.split('/').length - 1]}</p>
                                            {_item.integrationName &&
                                                <Badge className="w-fit h-fit text-xs italic rounded-xl">{_item.integrationName}</Badge>}

                                        </div>
                                    </div>
                                </div>
                            )
                            )}
                        </div>
                    </div>
                }
                {
                    !props.isUser &&
                    <div className="flex flex-row w-full space-x-2 items-start leading-none py-2">
                        <BookCheck className="size-4" />
                        <span>Answer</span>
                    </div>
                }
                <MarkdownPreview
                    className={cn("w-full max-w-full markdown-preview", props.isUser ? "custom-markdown-white" : "custom-markdown")}
                    source={replaceTextWithDiv(props.text)}
                    style={{ backgroundColor: "transparent" }}
                    components={{
                        a: LinkRenderer,  // Override the 'a' tag to use the custom LinkRenderer
                    }}
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeKatex]}
                />
                {
                    !props.isUser &&
                    <div className="flex flex-row w-full space-x-1 items-start leading-none py-2">
                        <Button variant="ghost" size="icon" className="rounded-lg p-1" >
                            {
                                !playText &&
                                <Speech className="size-5" onClick={() => handleContentPlay(replaceTextWithDiv(props.text), !playText)} />
                            }
                            {
                                playText &&
                                <StopCircle className="size-5" onClick={() => handleContentPlay(replaceTextWithDiv(props.text), !playText)} />

                            }
                        </Button>
                        <Button variant="ghost" size="icon" className="rounded-lg p-1" >
                            {
                                textCoppied &&
                                <CopyCheck className="size-5" />
                            }
                            {
                                !textCoppied &&
                                <Copy className="size-5" onClick={() => copyTextToClipboard(replaceTextWithDiv(props.text))} />
                            }
                        </Button>
                        {/* <Button variant="ghost" size="icon" className="rounded-lg p-1" >
                            <RefreshCcw className="size-5" />
                        </Button> */}
                    </div>
                }
            </div>
            {props.isUser &&
                <div className="">
                    <Avatar className="size-8 border-2 border-primary/60 bg-card items-center justify-center">
                        <span>
                            <AvatarImage src={userInfo?.profilePicture} alt="Avatar" />
                            <AvatarFallback className="bg-transparent uppercase">{userInfo?.userName?.substring(0, 2)}</AvatarFallback>
                        </span>
                    </Avatar>
                </div>
            }
        </div>
    )
}