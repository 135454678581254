import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./core/middlewares/protectedAuthProvider";
import LoginForm from "./auth/login/loginForm";
import SignupForm from "./auth/signup/signupForm";
import NotFound from "./core/layout/error/notFound/notFound";
import ForgetPassword from "./auth/forget-password/forgetPasswordForm";
import FileOverview from "./pages/files-overview/files-overview";
import UserSettings from "./pages/settings/user/user-settings";
import { useSelector } from "react-redux";
import { General } from "./pages/settings/general/general";
import { ResetPassword } from "./pages/settings/reset-password/reset-password";
import ConfirmEmail from "./auth/confirm-email/confirm-email";
import ForgetpasswordInfo from "./auth/forgetPasswordInfo/forgetPasswordInfo";
import ResetpasswordAuth from "./auth/resetpasswordAuth/resetpasswordAuth";
import { FullScreenDropedViewer } from "./core/components/common/droped-file-uploader/full-screen-droped-file-viewer";
import EmptyLayout from "./core/layout/empty/empty";
import { FileEditor } from "./shared/components/file-editor/file-editor";
import LoggedEmptyLayout from "./core/layout/protected/logged-empty";
import { PaymetnsMethods } from "./pages/settings/payments/paymetnes";
import { SubcriptionMethod } from "./pages/subcription-plan/priceList";
import { Integrations } from "./pages/settings/integrations/integration";
import { ChatLayout } from "./pages/chat/chat-layout";
import { SharedLayout } from "./core/layout/sharedChat/sharedChat";
import { SyncStatusView } from "./pages/settings/integrations/sync-status";
import { ManagementWorkspace } from "./pages/settings/workspaceManagement/managementWorkspace";
import { ApitKeyManagement } from "./pages/settings/apiKeys/apiKey";
import { SearchCockpitManagement } from "./pages/settings/searchCockpit/searchCockpit";
import CategoryConfig from "./pages/settings/category-config/categoryConfig";
// Define public routes accessible to all users
//   const routesForPublic = [


const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <ProtectedRoute />,
        children: [
            {
                path: "home",
                element: <ChatLayout />,
            },
            {
                path: "home/c/:chat-id",
                element: <ChatLayout />,
            },
            {
                path: "main/pricing-table/:id",
                element: <SubcriptionMethod />,
            },
            {
                path: "profile",
                element: <UserSettings />,
                children: [
                    {
                        path: "",
                        element: <Navigate to="general" />,
                    },
                    {
                        path: "general",
                        element: <General />,
                    },
                    {
                        path: "integrations",
                        element: <Integrations />,
                    },
                    {
                        path: "integrations/:pluginId",
                        element: <Integrations />,
                    },
                    {
                        path: "reset-password",
                        element: <ResetPassword />,
                    },
                    {
                        path: "payments",
                        element: <PaymetnsMethods/>,
                    },
                    {
                        path: "userManagement",
                        element: <ManagementWorkspace/>,
                    },
                    {
                        path: "APIkey",
                        element: <ApitKeyManagement/>,
                    },
                    {
                        path: "integrations/sync-progress",
                        element: <SyncStatusView />,
                    },
                    {
                        path: "searchCockpit",
                        element: <SearchCockpitManagement />,
                    },
                    {
                        path: "categoryConfig",
                        element: <CategoryConfig />
                    }
                ]
            },
            {
                path: '',
                element: <Navigate to='/home' />,
            },
        ],
    },
    {
        path: "/",
        element: <LoggedEmptyLayout />,
        children: [
            {
                path: "/browse/upload",
                element: <FullScreenDropedViewer />,
            },
            {
                path: "/shared/:token",
                element: <SharedLayout/>,
            },
            {
                path: "/browse/edit",
                element: <FileEditor />,
            },
        ]
    },
    {
        path: "/preview/:fileId",
        element: <FileOverview />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
];

// Define routes accessible only to non-authenticated users
const routesForNotAuthenticatedOnly = [
    {
        path: '/',
        element: <EmptyLayout />,
        children: [
            {
                path: "",
                element: <Navigate to="login" />,
            },
            {
                path: "login",
                element: <LoginForm />,
            },
            {
                path: "sign-up",
                element: <SignupForm />,
            },
            {
                path: "forget-password",
                element: <ForgetPassword />,
            },
            {
                path: "forgetpasswordInfo",
                element: <ForgetpasswordInfo />,
            },
            {
                path: "email-confirmation/:token",
                element: <ConfirmEmail />,
            },
            {
                path: "reset-password",
                element: <ResetpasswordAuth />,
            },
            {
                path: '*',
                element: <Navigate to='/login' />,
            },
        ]
    },
    {
        path: '*',
        element: <Navigate to='/' replace />,
    },
];;


const Routes = () => {
    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated)
    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...(!isAuthenticated ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
        ...routesForNotAuthenticatedOnly
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;