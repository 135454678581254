import { isEmpty } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { Button } from "../../components/ui/button"
import { resetSelectedChat, selectToken, sharedCheck } from "../../../redux-store/reducers/chat-reducers"
import { AppDispatch, RootState } from "../../../redux-store/stores/store"
import { ChatService } from "../../../shared/services/chat/chat-service"
import { ChatBubble } from "../../../pages/chat/components/chat-bubble"
import { logout } from "../../../redux-store/reducers/auth-reducers";
import { resetState } from "../../../redux-store/reducers/dashboard-reducer";
import { sharedContinueCaht } from "../../../shared/services/sharedLink"
import { InfoIcon } from "lucide-react"


export const SharedLayout = () => {

    const [_prompt, setPrompt] = useState<string>('')
    const [isNewChat, setIsNewChat] = useState<boolean>(false)


    const dispatch = useDispatch<AppDispatch>()

    const [recivingResponse, setRecivingResponse] = useState(false)

    const chatServiceInstatnce = useMemo(() => new ChatService(), []);

    const { selectedToken, messages, chatSelected, error } = useSelector((state: RootState) => state.chat)


    const activeToken = localStorage.getItem('active-token')

    const navigate = useNavigate()
    const params = useParams()

    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (params && params['token']) {
            dispatch(selectToken(params['token']))
        }
        if (window.location.pathname.includes('shared')) {
            dispatch(sharedCheck(true))
        }
        if (isEmpty(params)) {
            dispatch(resetSelectedChat())
        }
        return () => {
            dispatch(resetSelectedChat())
            setRecivingResponse(false)
        }
    }, [params])

    const haddleCreatechat = () => {
        dispatch(sharedContinueCaht(selectedToken)).then((res) => {
            if (res.payload.chatId) {
                navigate(`/home/c/${res.payload.chatId}`);
            }
        }).catch((error) => {
            console.error("Failed to create chat:", error);
        });
    }


    const logoutChat = () => {
        dispatch(resetState())
        dispatch(logout())
    }


    useEffect(() => {
        if (selectedToken) {
            console.log('token', selectedToken)
            dispatch(chatServiceInstatnce.GetTokenMessages(selectedToken))
            setIsNewChat(false)

        }
    }, [selectedToken])



    return (
        <div className="flex flex-row w-full h-full transition-colors z-0">
            <div className="flex flex-col w-full px-4 md:px-10 bg-white h-full">
                <div className="flex flex-col w-full h-full rounded-xl  mx-auto relative justify-between items-center">
                    <div className="hidden md:flex top-0 flex-row w-full h-fit bg-white space-x-2">
                    </div>
                    {(isNewChat || chatSelected) &&
                        <div
                            ref={chatContainerRef}
                            className="flex flex-col max-w-7xl w-full mx-auto h-[92vh] overflow-y-auto pt-10 md:pt-0 md:px-4  flex-initial"
                        >
                            {messages.length === 0 ? (
                                <div className="flex items-center justify-center h-full">
                                    <p className="text-center text-gray-500">
                                        <div className="flex flex-col justify-center items-center">
                                            <InfoIcon className="size-20 mb-4"></InfoIcon>
                                        </div>
                                        You have no access to read this chat, please log in with an access account.
                                    </p>
                                </div>
                            ) : (
                                messages.map((item, index) => (
                                    <div key={index} className="flex flex-col w-full h-fit space-y-4 py-4">
                                        <ChatBubble key={`message-${item.id}-q`} {...item.questionDetails} />
                                        <ChatBubble key={`message-${item.id}-a`} {...item.answerDetails} />
                                    </div>
                                ))
                            )}
                            {chatSelected && recivingResponse && (
                                <div className="flex flex-row space-x-2 pl-8">
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-0"></div>
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-75"></div>
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-150"></div>
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>
                    }

                    {(activeToken && error !== 'Invalid token') && (
                        <div className="flex flex-row right-0 items-end justify-end mt-2">
                            <Button onClick={haddleCreatechat}>
                                Continue Chat
                            </Button>
                        </div>
                    )}

                    {(activeToken && error === 'Invalid token') && (
                        <div className="flex flex-row right-0 items-end justify-end">
                            <Button className="space-x-2" onClick={logoutChat}>
                                <NavLink to="/login">
                                    <p>Continue to Log in</p>
                                </NavLink>
                            </Button>
                            <Button className="space-x-2 ml-2" onClick={logoutChat}>
                                <NavLink to="/sign-up">
                                    <p>Countinue to Sign up</p>
                                </NavLink>
                            </Button>
                        </div>
                    )}

                    {(!activeToken) && (
                        <div className="flex flex-row items-end justify-end mt-2">
                            <Button className="space-x-2" onClick={logoutChat}>
                                <NavLink to="/login">
                                    <p>Continue to Log in</p>
                                </NavLink>
                            </Button>
                            <Button className="space-x-2 ml-2" onClick={logoutChat}>
                                <NavLink to="/sign-up">
                                    <p>Countinue to Sign up</p>
                                </NavLink>
                            </Button>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}