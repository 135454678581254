import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, } from "../../../core/components/ui/dialog";
import { Button } from "../../../core/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { setCategoryFolder } from "../../../redux-store/reducers/chat-reducers";
import { Label } from "../../../core/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../core/components/ui/tabs";
import { categoryPathSend } from "../../../shared/services/categoryPath";
import { ArrowLeft, Info } from "lucide-react";
import { categoryPath, clearCategoryPath } from "../../../redux-store/reducers/categoryPath-reducer";
import FileIconViewer from "../../../shared/components/file-icon-viewer/file-icon-viewer";
import floderIcon from '../../../assets/images/Others/Folder.png'
import { categoryFolder } from "../../../shared/models/categoryConfig-model";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../core/components/ui/select";
import { Input } from "../../../core/components/ui/input";

interface FileDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryPop: React.FC<FileDialogProps> = ({ open, setOpen }) => {
  const dispatch: AppDispatch = useDispatch();
  const [primaryPath, setPrimaryPath] = useState<string[]>([]);
  const [referencePath, setReferencePath] = useState<string[]>([]);
  const [isPrimary, setIsPrimary] = useState(true);
  const [disableDropDown, setdisabledropdown] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const initialLoad = useRef(true);

  const { primary, refrence, selectCategory } = useSelector((state: RootState) => state.chat);
  const { categoryPath } = useSelector((state: RootState) => state.categoryPath);

  const [folderStack, setFolderStack] = useState<string[]>([]);


  let primaryFolders: categoryFolder[] = [];
  let referenceFolders: categoryFolder[] = [];

  if (selectCategory?.categoryFolders.length === 1) {
    primaryFolders = selectCategory?.categoryFolders ?? [];
    // referenceFolders = selectCategory?.categoryFolders ?? [];
  } else if (selectCategory?.categoryFolders.length === 2) {
    primaryFolders = selectCategory?.categoryFolders.slice(1, 2) ?? []
    // referenceFolders = selectCategory?.categoryFolders.slice(1, 2) ?? [];
  } else {
    primaryFolders = selectCategory?.categoryFolders.slice(1, 2) ?? [];
    referenceFolders = selectCategory?.categoryFolders.slice(2) ?? [];
  }


  useEffect(() => {
    if (initialLoad.current && primaryFolders.length > 0) {
      handlePathSelect(primaryFolders[0].folder, false);
      initialLoad.current = false;
    }
  }, [primaryFolders]);


  useEffect(() => {
    setPrimaryPath(primary ?? []);
    setReferencePath(refrence ?? []);
  }, [primary, refrence]);

  const handlePathSelect = (path: string, addToStack = true) => {
    const checkpath = path.includes('/drive/root');
    if (checkpath) {
      setdisabledropdown(true)
    }
    const folderName = path.split('/').pop() || "";
    if (addToStack) {
      setFolderStack((prevStack) => [...prevStack, folderName]);
      setSelectedFolder(path);
    } else {
      setFolderStack([folderName]);
      setSelectedFolder(path);
    }
    dispatch(categoryPathSend(path));
  };

  const handleFileSelect = (filePath: string, isPrimaryPath: boolean) => {
    const pathArray = isPrimaryPath ? primaryPath : referencePath;
    const setPathArray = isPrimaryPath ? setPrimaryPath : setReferencePath;

    if (pathArray.includes(filePath)) {
      setPathArray(pathArray.filter((file) => file !== filePath));
    } else {
      setPathArray([...pathArray, filePath]);
    }
  };

  const handleBack = () => {
    if (folderStack.length > 1) {
      const updatedStack = folderStack.slice(0, -1);
      const previousFolder = updatedStack[updatedStack.length - 1] || null;
      setFolderStack(updatedStack);
      setSelectedFolder(previousFolder);
      dispatch(categoryPathSend(previousFolder || ""));
    } else {
      setFolderStack([]);
      setSelectedFolder(null);
    }

    if (folderStack.length === 2) {
      setdisabledropdown(false)
    }
  };

  const SavePath = () => {
    const requestBody = {
      primaryPath,
      referencePath,
    };
    dispatch(setCategoryFolder(requestBody));
    setOpen(false);
    setFolderStack([]);
    setSelectedFolder(null);
    dispatch(clearCategoryPath([]));

  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // Filtered items based on the search term
  const filteredItems = categoryPath.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderFolderContents = (folders: categoryFolder[], isPrimaryPath: boolean) => {
    const pathArray = isPrimaryPath ? primaryPath : referencePath;
    return (
      <>
        <Label className="text-sm font-extrabold">Folder Contents</Label>
        {selectedFolder && !isPrimaryPath && !disableDropDown && (
          <div className="flex flex-col w-[30%] items-start mb-2 mt-2">
            <Select key={`${selectedFolder}-${isPrimaryPath}`} onValueChange={(value) => handlePathSelect(value, false)}>
              <SelectTrigger className="md:min-w-[280px] rounded-2xl p-2 bg-gray-200 hover:bg-gray-300">
                <SelectValue placeholder={selectedFolder || "Select a Folder"} />
              </SelectTrigger>
              <SelectContent>
                {folders.map((folderObj: { folder: string; integration: number }, index: number) => (
                  <SelectItem key={index} value={folderObj.folder}>
                    {folderObj.folder.length > 50 ? `${folderObj.folder.slice(0, 50)}...` : folderObj.folder}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        {categoryPath && categoryPath.length > 0 && selectedFolder && (
          <div className="flex text-sm justify-center items-center w-full text-gray-500 ">
            <Input
              type="text"
              placeholder="Search items..."
              value={searchTerm}
              onChange={(event) => handleSearchChange(event)}
            />
          </div>
        )}
        <div className="flex sm:max-w-[950px] max-h-[200px] lg:max-h-[330px] max-h-[70vh] max-w-[100vw] overflow-hidden overflow-y-auto">
          {!selectedFolder && folders.length === 0 && (
            <div className="flex text-sm  justify-center items-center w-full text-gray-500">
              <Info className="mr-2"></Info>
              Please select folder content path from category</div>
          )}

          <div className="grid grid-cols-4 sm:grid-cols-4 lg:grid-cols-8 gap-4 mt-2">
            {categoryPath && categoryPath.length === 0 && selectedFolder && (
              <div className="flex text-sm absolute justify-center items-center w-full text-gray-500 ">
                <Info className="mr-2"></Info>
                Files or folders do not exist
              </div>
            )}


            {[...filteredItems]
              .sort((a, b) => a.type - b.type)
              .map((item: categoryPath, index: number) => (
                <div key={index} className="flex flex-col items-start cursor-pointer relative">
                  <div
                    className="relative p-5 bg-gray-200 rounded-lg hover:bg-gray-300">
                    <input
                      type="checkbox"
                      checked={pathArray.includes(item.path)}
                      onChange={() => {
                        handleFileSelect(item.path, isPrimaryPath);
                      }}
                      className="absolute top-0 right-0 m-2"
                    />
                    <div>
                      {item.type === 0 ? (
                        <div className="flex-shrink-0 w-[40px] h-[40px] shadow-lg rounded-xl items-center flex flex-col justify-center" onClick={() => handlePathSelect(item.path)}>
                          <img alt="Folder Icon" src={floderIcon} className="w-full h-full object-contain" />
                        </div>
                      ) : (
                        <div className="flex flex-col w-fit space-y-2 h-fit max-w-[75px]" onClick={() => handleFileSelect(item.path, isPrimaryPath)}>
                          <div className="flex-shrink-0 w-[40px] h-[40px] shadow-lg rounded-xl">
                            <FileIconViewer className="w-full h-full" file={item.path} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="text-xs mt-1 text-warp" title={item.name}>
                    {item.name.length > 15 ? `${item.name.slice(0, 12)}...` : item.name}
                  </span>
                </div>
              ))
            }
          </div>
        </div>
      </>
    )
  }

  const handleDialogClose = (isOpen: boolean) => {
    setOpen(isOpen);
    if (!isOpen) {
      setFolderStack([]);
      setSelectedFolder(null);
      dispatch(clearCategoryPath([]));
    }
  };

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onOpenChange={handleDialogClose}>
        <DialogContent className="sm:max-w-[950px] lg:max-h-[630px] max-h-[90vh] max-w-[100vw] rounded">
          <DialogHeader className="flex flex-col items-left border-b">
            <DialogTitle className="font-extrabold text-lg">Category Folder Path</DialogTitle>
            <DialogDescription className="border-b pb-2">Your selected category folder path type below.</DialogDescription>
          </DialogHeader>
          <Tabs
            defaultValue="primary"
            onValueChange={(value) => {
              setIsPrimary(value === "primary");
              setFolderStack([]);
              setSelectedFolder(null);
              dispatch(clearCategoryPath([]));

              const foldersToSelect = value === "primary" ? primaryFolders : referenceFolders;
              if (foldersToSelect.length > 0) {
                handlePathSelect(foldersToSelect[0].folder);
              }
            }}
          >
            {folderStack.length > 1 && (
              <div className="flex items-center space-x-2">
                <Button variant="outline" size={'sm'} onClick={handleBack}>
                  <ArrowLeft />
                </Button>
                <span className="text-sm font-semibold">{folderStack.join(" / ")}</span>
              </div>
            )}
            <TabsList className="bg-transparent p-1 rounded-lg flex space-x-2 justify-centre items-center w-full">
              <TabsTrigger
                value="primary"
                className="px-6 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
              >
                Application
              </TabsTrigger>
              {
                referenceFolders && referenceFolders.length > 0 &&
                <TabsTrigger
                  value="reference"
                  className="px-6 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
                >
                  Reference
                </TabsTrigger>
              }

            </TabsList>

            <TabsContent value="primary">{renderFolderContents(primaryFolders, true)}</TabsContent>
            {
              referenceFolders && referenceFolders.length > 0 &&
              <TabsContent value="reference">{renderFolderContents(referenceFolders, false)}</TabsContent>
            }
          </Tabs>
          <div className="flex flex-row justify-end items-end space-x-2">
            <Button size="lg" variant='outline' onClick={closeDialog}>
              Close
            </Button>
            <Button size="lg" onClick={SavePath} disabled={!(primaryPath.length > 0 || referencePath.length > 0)}>
              Save Path(s) {(primaryPath && referencePath && (primaryPath.length + referencePath.length) > 0) && `(${primaryPath.length + referencePath.length}) selected`}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CategoryPop;