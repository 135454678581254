import { createSlice } from '@reduxjs/toolkit';
import { getIntegrationConfig, saveIntegrationConfig, deleteIntegrationConfig } from '../../shared/services/integrationConfig';
import { IntegrationConfigModel } from '../../shared/models/integrationConfig-model';

const initialState: IntegrationConfigModel = {
    integrationConfig : [],
    loading: false,
    error: '',
    success: false,
    resetSuccess: false,
}

const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getIntegrationConfig.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getIntegrationConfig.fulfilled, (state, { payload }) => {
                state.integrationConfig = payload; 
                state.loading = false;
                state.success = true;
              })
              
            .addCase(getIntegrationConfig.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(saveIntegrationConfig.fulfilled, (state, { payload }) => {
                state.integrationConfig = payload;
                state.loading = false;
                state.success = true;
            })
            

            .addCase(saveIntegrationConfig.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })

            // .addCase(saveIntegrationConfig.fulfilled, (state, { payload }) => {
            //     const exists = state.integrationConfig.some(config => config.integrationId === payload.integrationId);
                
            //     if (exists) {
            //       state.integrationConfig = state.integrationConfig.map(config =>
            //         config.integrationId === payload.integrationId
            //           ? { ...config, ...payload }  // Update existing config
            //           : config
            //       );
            //     } else {
            //       state.integrationConfig.push(payload);  // Add new config
            //     }
                
            //     state.loading = false;
            //     state.success = true;
            //   })
              
            .addCase(saveIntegrationConfig.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(deleteIntegrationConfig.fulfilled, (state, { payload }) => {
                state.integrationConfig = state.integrationConfig.filter(config => config.integrationId !== payload);
                state.loading = false;
                state.success = true;
              })
              .addCase(deleteIntegrationConfig.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
              })
              .addCase(deleteIntegrationConfig.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to delete integration';
              });

    }
})


export const { resetState,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer
