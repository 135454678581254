import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatService } from '../../shared/services/chat/chat-service'
import { getCategoryConfig } from "../../shared/services/categoryConfig";
import { Categories } from "../../shared/models/categoryConfig-model";

export interface Chat {
    chatDetails: {
        name: string
        tokens: number
        isSelect: boolean
    }
    icon: string
    to: string
    id: number
    status: number
    type: number
    company: number
    createdUser: number,
    recivingResponse: boolean
}

export interface References {
    fileName: string
    fileUrl: string
    thumbnailUrl: string
    contentType: string
    fileId: 4489
    pageNumbers: number[]
    folderPath: string
    filePath: string
    type: 0 | 1
    folderUrl: string | null
    integrationName: string | null
    chunkContent: string | null
}

export interface Message {
    id: number
    questionDetails: {
        text: string
        createdTime: string
        tokens: number | null
        isUser: boolean
        references: References[] | null
        primary: string[]
        reference: string[]
        isCollate: boolean
        sources: References[] | null
        categories: string[]
        includeFiles: boolean
    }
    answerDetails: {
        text: string | null
        createdTime: string | null
        tokens: number | null
        references: null
    }
    messageDetails: {
        text: string | null
        createdTime: string | null
        fileId: number
    }
    chatBox: number
    chatBoxId: number
    company: number
    status: number
    type: number
    updateInfo: any
}

export interface IChatState {
    chatList: Chat[];   // Adjust the type based on your data structure
    messages: Message[];   // Adjust the type based on your data structure
    loading: boolean;
    error: string | null;
    selectedChatId: number | null;
    selectedToken: string | null;
    chatSelected: boolean
    newMessage: boolean;
    isShared: boolean;
    categories: Categories[],
    selectCategory: Categories | null,
    chatModel: string | null;
    refrence: string[] | null;
    primary: string[] | null;
    isCollate: boolean
    chatIncludeFiles: boolean
}

const initialState: IChatState = {
    chatList: [],
    messages: [],
    loading: false,
    error: null,
    selectedChatId: null,
    selectedToken: null,
    chatSelected: false,
    newMessage: false,
    selectCategory: null,
    isShared: false,
    chatModel: null,
    refrence: [],
    primary: [],
    isCollate: false,
    chatIncludeFiles: false,
    categories: []

};
const chatServiceInstance = new ChatService();

const chatSlice = createSlice({
    name: "chat",
    initialState: initialState,
    reducers: {
        selectChat: (state, { payload }) => {
            state.selectedChatId = parseInt(payload)
            state.chatSelected = true
        },
        selectToken: (state, { payload }) => {
            state.selectedToken = payload
            state.chatSelected = true
        },
        sharedCheck: (state, { payload }) => {
            state.isShared = payload
        },
        resetChatList: (state) => {
            state.chatList = []
        },
        resetSelectedChat: (state) => {
            state.selectedChatId = null
            state.selectedToken = null
            state.messages = []
            state.chatSelected = false
            state.selectCategory = null
        },
        setNewMessageStatus: (state, { payload }) => {
            state.newMessage = payload
        },
        updateMessage: (state, { payload }) => {
            if (state.messages && state.messages[state.messages.length - 1]) {
                state.messages[state.messages.length - 1].answerDetails.text = payload
            }
        },
        updateQuestion: (state, { payload }) => {
            const index = state.messages.length > 0 ? state.messages.length : 0
            state.messages[index] = {
                id: 0,
                questionDetails: {
                    text: payload.Question,
                    createdTime: '',
                    tokens: null,
                    isUser: true,
                    references: null,
                    sources: null,
                    categories: payload.Categories,
                    includeFiles: payload.IncludeFiles
                }
            } as Message
        },
        setChatModel: (state, { payload }) => {
            state.chatModel = payload;
        },
        setIsCollate: (state, { payload }) => {
            state.isCollate = payload;
        },
        setCategory: (state, { payload }) => {
            state.selectCategory = payload;
        },
        setCategoryFolder: (state, action: PayloadAction<{ primaryPath: string[], referencePath: string[] }>) => {
            state.primary = action.payload.primaryPath;
            state.refrence = action.payload.referencePath;
        },
        setChatIncludeFiles: (state, { payload }) => {
            state.chatIncludeFiles = payload;
        },
    },
    extraReducers: (builder) => {
        // login user
        builder
            .addCase(chatServiceInstance.GetChatList.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetChatList.fulfilled, (state, action) => {
                state.chatList = action.payload;
                state.loading = false;
                state.chatList.forEach(item => {
                    item.to = `/home/c/${item.id}`
                })
                state.chatList = state.chatList.sort((a, b) => b.id - a.id)
            })
            .addCase(chatServiceInstance.GetChatList.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.GetMessages.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetMessages.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                action.payload.forEach((item: any) => {
                    item.questionDetails.isUser = true
                })
                const index = state.messages.length > 0 ? state.messages.length - 1 : 0
                state.messages.length > 0 ?
                    state.messages[index] = action.payload[index] :
                    state.messages = action.payload

                setNewMessageStatus(false)
            })
            .addCase(chatServiceInstance.GetMessages.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })


            .addCase(chatServiceInstance.GetTokenMessages.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetTokenMessages.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                action.payload.forEach((item: any) => {
                    item.questionDetails.isUser = true
                })
                const index = state.messages.length > 0 ? state.messages.length - 1 : 0
                state.messages.length > 0 ?
                    state.messages[index] = action.payload[index] :
                    state.messages = action.payload

                setNewMessageStatus(false)
            })
            .addCase(chatServiceInstance.GetTokenMessages.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.SendMessage.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.SendMessage.fulfilled, (state, action: PayloadAction<any>) => {
                action.payload.questionDetails.isUser = true
                const index = state.messages.length > 0 ? state.messages.length - 1 : 0
                state.messages[index] = action.payload
                state.loading = false;
            })
            .addCase(chatServiceInstance.SendMessage.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.DeleteMessage.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.DeleteMessage.fulfilled, (state, action: PayloadAction<any>) => {
                state.messages = state.messages.filter(msg => msg.id !== action.payload.id); // Example: remove the message
                state.loading = false;
            })
            .addCase(chatServiceInstance.DeleteMessage.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.DeleteChat.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.DeleteChat.fulfilled, (state, action: PayloadAction<any>) => {
                state.chatList = state.chatList.filter(chat => chat.id !== action.payload.id); // Example: remove the chat
                state.loading = false;
            })
            .addCase(chatServiceInstance.DeleteChat.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })


            .addCase(getCategoryConfig.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCategoryConfig.fulfilled, (state, action: PayloadAction<any>) => { // Example: remove the chat
                state.loading = false;
                state.categories = action.payload.res
            })
            .addCase(getCategoryConfig.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.updateTitle.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.updateTitle.fulfilled, (state, action: PayloadAction<any>) => {
                const existingChatIndex = state.chatList.findIndex(chat => chat.id === action.payload.id);

                if (existingChatIndex !== -1) {
                    state.chatList[existingChatIndex].chatDetails.name = action.payload.chatDetails.name;

                    state.chatList[existingChatIndex] = {
                        ...state.chatList[existingChatIndex],
                        ...action.payload,
                    };
                } else {
                    state.chatList.push(action.payload);
                }

                state.loading = false;
            })
            .addCase(chatServiceInstance.updateTitle.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })
    }
});

export const { selectChat, selectToken, resetSelectedChat, setNewMessageStatus, updateMessage, setCategory, setChatModel, sharedCheck, setCategoryFolder, setChatIncludeFiles, updateQuestion, setIsCollate, resetChatList } = chatSlice.actions;
export default chatSlice.reducer;