import { createSlice } from '@reduxjs/toolkit'
import { categoryPathSend } from '../../shared/services/categoryPath';

export interface categoryPath {
    name: string,
    path: string,
    type: number,
}
export interface CategoryPathModel {
    categoryPath: categoryPath[],
    loading: boolean,
    error: string | null,
    success: boolean,
    resetSuccess: boolean,
}


const initialState: CategoryPathModel = {
    categoryPath: [],
    loading: false,
    error: null,
    success: false,
    resetSuccess: false,
}


const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
        clearCategoryPath(state,{payload}) {
            state.categoryPath = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(categoryPathSend.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(categoryPathSend.fulfilled, (state, { payload }) => {
                state.categoryPath = payload
                state.loading = false;
                state.success = true;
            })
            .addCase(categoryPathSend.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })
    }
})


export const { resetState,resetSuccess , clearCategoryPath } = integrationSlice.actions
export default integrationSlice.reducer