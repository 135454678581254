import { createSlice } from '@reduxjs/toolkit';
import { sharedContinueCaht, sharedLinkReq } from '../../shared/services/sharedLink';
import { SharedModel } from '../../shared/models/shared-modek';

interface sharedLink {
    loading: boolean;
    error: string | null;
    sharedLink: SharedModel | null;
    chatId:number | null;
    success: boolean;
}

const initialState: sharedLink = {
    loading: false,
    error: null,
    sharedLink: null ,
    chatId:null,
    success: false,
};

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.sharedLink = null;
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sharedLinkReq.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(sharedLinkReq.fulfilled, (state, { payload }) => {
                if (state.sharedLink) {
                    state.sharedLink.link = payload.link;
                } else {
                    state.sharedLink = { link: payload.link };
                }
                state.loading = false;
                state.success = true;
            })
            .addCase(sharedLinkReq.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch teams';
            })

            .addCase(sharedContinueCaht.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(sharedContinueCaht.fulfilled, (state, { payload }) => {
                console.log(payload.chatId);
                state.chatId = payload.chatId;
                state.loading = false;
                state.success = true;
            })
            .addCase(sharedContinueCaht.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch teams';
            })
    },
});

export const { resetState, resetSuccess } = teamSlice.actions;
export default teamSlice.reducer;
