import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '.';



export const getSubcriptionPlans = createAsyncThunk(
  'api/SubcriptionPlan',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/subscriptions');
      console.log(data);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const checkOutsubcription = createAsyncThunk(
  'api/checkOutSubcription',
  async (checkoutIds: any, { rejectWithValue }) => {
    console.log(checkoutIds);
    const api = axiosInstance
    try {
      const { data } = await api.post(`Subscriptions/create-customer-session?productId=${checkoutIds.subcriptionId}&priceId=${checkoutIds.priceId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)


