import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../core/components/ui/dialog";
import phoneIcon from '../../assets/images/phone.png';
import mailIcon from '../../assets/images/mail.png';

interface FileDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    files?: any[] | null;
    setFiles?: React.Dispatch<React.SetStateAction<any[] | null>>;
}

const ContactUs: React.FC<FileDialogProps> = ({ open, setOpen }) => {

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="sm:max-w-[600px] bg-gray-300 ">
                <DialogHeader className="flex flex-col items-center justify-center">
                    <DialogTitle className="font-bold text-2xl">Contact Us</DialogTitle>
                    <DialogDescription className="text-black">Contact us to create a plan that aligns with your business needs.</DialogDescription>
                </DialogHeader>
                <ul className="list-disc list-inside gap-2 ">
                    <div className="flex grid grid-cols-2 gap-5 text-white">
                        <div className="card bg-gray-800 rounded-lg p-8 w-full shadow-md text-center flex flex-col">
                            <div className="flex flex-col mb-4 bg-white shadow-md rounded-full flex self-center items-center justify-center w-16 h-16">
                                <img src={phoneIcon} alt="PhoneIcon" className="mx-auto w-10" />
                            </div>
                            <h3 className="text-lg font-semibold mb-2">Give us a call</h3>
                            <p className="mb-4">We are standing by 24/7 hours</p>
                            <a href="tel:+442035403828" className="inline-block bg-blue-700 text-white py-2 px-5 rounded-full font-bold hover:bg-blue-400 hover:text-black no-underline w-full mt-auto">(+44) 20 3540 3828</a>
                        </div>
                        <div className="card bg-gray-800 rounded-lg p-8 w-full shadow-md text-center flex flex-col">
                            <div className="icon mb-4 bg-white shadow-md rounded-full flex self-center items-center justify-center w-16 h-16">
                                <img src={mailIcon} alt="Email Icon" className="mx-auto w-10" />
                            </div>
                            <h3 className="text-lg font-semibold mb-2">Email us</h3>
                            <p className="mb-4">Drop us an email and you’ll receive a reply within 24 hours</p>
                            <a href="mailto:info@example.com" className="inline-block bg-blue-700 text-white py-2 px-5 rounded-full font-bold hover:bg-blue-400 hover:text-black no-underline w-full mt-auto">Email us</a>
                        </div>
                    </div>
                </ul>
            </DialogContent>
        </Dialog>
    );
};

export default ContactUs;