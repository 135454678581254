import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from ".";



export const getuserDetails = createAsyncThunk(
    'api/company/users',
    async (_, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.get('/company/users');
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )


export const teamUserAdd = createAsyncThunk(
    'api/userAdd',
    async (userDetails:any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.post('users/user-add',userDetails);
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )

  export const deleteUser = createAsyncThunk(
    'api/deleteUser',
    async (UserId: any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.delete(`users/${UserId}`,)
        return data
      } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const deleteUserProfile = createAsyncThunk(
    'api/deleteUserProfile',
    async (UserId: any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.delete(`users/remove/${UserId}`,)
        return data
      } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const updateUserdata = createAsyncThunk<any, { formData: any, userId: number | undefined }>(
    'api/updateeUser',
    async (  { formData, userId }, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.put(`users/${userId}`,formData)
        return data
      } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const restoreUser = createAsyncThunk(
    'api/restoreUser',
    async (UserId: any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.put(`users/${UserId}/restore-user`,)
        return data
      } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )