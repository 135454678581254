import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../../../core/components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog"
import { Input } from "../../../core/components/ui/input"
import { Label } from "../../../core/components/ui/label"
import { openCloseApiKeyDialog } from "../../../redux-store/reducers/userSetting-reducers"
import { AppDispatch, RootState } from "../../../redux-store/stores/store"
import { addIntegration } from "../../services/userSetting"

const AddApiKeyIntegration = ({ open, setOpen }: { open: boolean, setOpen: (value: boolean) => void }) => {
    const dispatch = useDispatch<AppDispatch>()

    const { uiConfig, success } = useSelector((state: RootState) => state.userSetting)
    const { register, handleSubmit, reset, formState: { errors } } = useForm()

    useEffect(() => {
        return () => {
            dispatch(openCloseApiKeyDialog(false))
            reset()
        }
    }, [open])

    useEffect(() => {
        if (success) {
            setOpen(false)
        }
    }, [success])

    const submitForm = (payload: any) => {
        const addRequest = {
            PluginId: uiConfig.pluginId,
            ...payload
        };
        dispatch(addIntegration(addRequest))
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className="flex flex-col items-center justify-center min-h-10 max-h-10">
                    <DialogTitle className="font-bold text-2xl">{uiConfig.title} Configuration</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <div className="flex flex-col w-full h-full px-6 py-2  min-h-96 ">
                    <form className="flex flex-col w-full h-full justify-between" onSubmit={handleSubmit(submitForm)}>
                        <div className="space-y-2 h-80 overflow-auto">
                            <Label>Name</Label>
                            <Input id="name" type="text" {...register('Name')} />
                            {uiConfig && uiConfig.uiConfig.map((item: any, index: number) => (
                                <div key={index}>
                                    <Label>{item.title}</Label>
                                    <Input id={`${item.key}`} type="text" {...register(item.key)} />
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-row w-full items-center justify-end py-4">
                            <Button type="submit">Save</Button>
                        </div>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AddApiKeyIntegration