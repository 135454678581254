import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from ".";



export const saveIntegrationConfig = createAsyncThunk(
    'api/plugin/filter-config',
    async (integrationConfig: any[], { rejectWithValue }) => {
        console.log('bbb',integrationConfig)
      const api = axiosInstance;
      try {
        const { data } = await api.put(`/plugin/filter-config`, integrationConfig); // Direct array
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );
  

  export const getIntegrationConfig= createAsyncThunk(
    'api/plugin/get-config',
    async (_, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.get('/plugin/filter-config');
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )

  export const deleteIntegrationConfig = createAsyncThunk(
    'api/plugin/delete-config',
    async (integrationId: string, { rejectWithValue }) => {
      const api = axiosInstance;
      try {
        await api.delete(`/plugin/filter-config/${integrationId}`);
        return integrationId;  
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );
