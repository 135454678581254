import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from ".";



export const sharedLinkReq = createAsyncThunk(
    'api/chatbox/share',
    async (request:any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.post('chatbox/share',request);
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )

  export const sharedContinueCaht = createAsyncThunk(
    'api/chatbox/continue',
    async (request:any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.get(`chatbox/continue?token=${request}`);
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )