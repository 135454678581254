import { Outlet } from "react-router-dom";
import Navbar from "../../components/common/navbar/navbar";


const LogedLayout = () => (
    <div className="dark:bg-boxdark-2 dark:text-bodydark  bg-primary/5">
        <div className="flex flex-col w-full h-screen overflow-hidden">
            <Navbar />
            <main className="flex flex-col max-w-screen h-full min-h-[calc(100vh_-_theme(spacing.18))]">
                <Outlet />
            </main>
        </div>
    </div>
)

export default LogedLayout