import { useEffect, useRef, useState } from "react";
import { Input } from "../../../core/components/ui/input"
export interface MessageCommandList {
    title: string;
    description: string;
    icon: string
    code: string
}
export interface ICommandInput {
    value: string
    placeholder: string
    handleKeyUp: (event: any) => void
    handleOnChange: (event: any) => void
    recivingResponse?: boolean
    setPrompt: (value: string) => void
    isDropdownAbove: boolean
}

const CommandInput: React.FC<ICommandInput> = ({ placeholder = "Type your promt here...", value, handleKeyUp, handleOnChange, recivingResponse, setPrompt, isDropdownAbove = false }) => {

    const commandList: MessageCommandList[] = [{
        title: "/file-only or /fo",
        description: 'To query through only files',
        icon: '',
        code: "file-only"
    }, {
        title: "/web-only or /wo",
        description: 'To query through only web',
        icon: '',
        code: "web-only"
    }, {
        title: "/web-also or /wa",
        description: 'To query through with web',
        icon: '',
        code: "web-also"
    }];

    const itemRefs = useRef<HTMLDivElement[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [showCommands, setShowCommands] = useState(false);
    const [filteredCommands, setFilteredCommands] = useState<MessageCommandList[]>(commandList);
    const [selectedCommandIndex, setSelectedCommandIndex] = useState(-1);

    useEffect(() => {
        // Check if the input starts with "/"
        const commandMatch = value.match(/^\/(\w+)/)
        const hasSpace = /\s/.test(value);
        if (!hasSpace && value === '/' || commandMatch) {
            let commandFilteredList = commandList
            if (commandMatch) {
                const command = commandMatch[1]
                commandFilteredList = commandList.filter(item => item.title.match(command))
            }

            setFilteredCommands(commandFilteredList);// Show all commands
            setShowCommands(true);
        }
        else {
            setShowCommands(false);
        }// eslint-disable-next-line
    }, [value]);

    const handleCommandSelect = (command: string) => {
        setPrompt(`/${command} `);
        setShowCommands(false);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleKeyDown = (e: any) => {
        if (showCommands) {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setSelectedCommandIndex(
                    (prevIndex) => (prevIndex + 1) % filteredCommands.length
                );
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setSelectedCommandIndex(
                    (prevIndex) =>
                        (prevIndex - 1 + filteredCommands.length) % filteredCommands.length
                );
            }
            else if (e.key === "Tab") {
                e.preventDefault();
                handleCommandSelect(filteredCommands[selectedCommandIndex].code);
            }
            else if (e.key === " ") {
                setShowCommands(false); // Close the command list on space
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setShowCommands(false);
            }
        };

        // Add event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    useEffect(() => {
        if (showCommands && inputRef?.current) {
            inputRef.current.focus();
        }
        return () => { if (inputRef.current) { inputRef.current.focus(); } }
        // eslint-disable-next-line
    }, [showCommands]);

    useEffect(() => {
        if (selectedCommandIndex !== -1 && itemRefs?.current[selectedCommandIndex]) {
            itemRefs?.current[selectedCommandIndex].focus();
        }// eslint-disable-next-line
    }, [selectedCommandIndex]);
    return (
        <div className="w-full">
            <Input
                id="message"
                autoFocus
                ref={inputRef}
                placeholder={placeholder}
                className="resize-none border-0 shadow-none focus-visible:ring-0 relative"
                value={value}
                onKeyUp={(event) => handleKeyUp(event)}
                onChange={(event) => handleOnChange(event)}
                onKeyDown={(event) => handleKeyDown(event)}
                disabled={recivingResponse}
            />

            {showCommands && (
                <div ref={dropdownRef} className={`absolute left-0 w-fit border border-gray-300 rounded-md shadow-lg z-30 ${isDropdownAbove ? 'bottom-full mb-2' : 'mt-2'
                    } bg-white`}>
                    <div className="flex flex-col w-full">
                        <div className="px-4 py-2">
                            <p className="font-bold">Commands</p>
                        </div>
                        <div className="space-y-2" tabIndex={0} role="menu" onKeyDown={(event) => handleKeyDown(event)}
                            aria-orientation="vertical">
                            {filteredCommands.map((command, index) => (
                                <div
                                    key={command.title}
                                    ref={(el) => { itemRefs.current[index] = el! }}
                                    className={`px-4 py-2 cursor-pointer text-sm h-fit w-full ${index === selectedCommandIndex ? "bg-gray-200" : "hover:bg-gray-100"
                                        }`}
                                    onClick={() => handleCommandSelect(command.code)}
                                >
                                    <div className="flex flex-col">
                                        <div className="font-semibold">{command.title}</div>
                                        <div className="text-xs text-gray-500">{command.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CommandInput