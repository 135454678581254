import { createSlice } from '@reduxjs/toolkit'
import { checkOutsubcription,  getSubcriptionPlans } from '../../shared/services/subcriptionPlan';
import { SubcriptionModel } from '../../shared/models/subcription-model';

const initialState: SubcriptionModel = {
    loading: false,
    subcriptionPlan:[],
    paymentHistory:[],
    paymentMethod:[],
    error: '',
    success: false,
    resetSuccess: false,
    progress: null,
}


const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubcriptionPlans.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getSubcriptionPlans.fulfilled, (state, { payload }) => {
                state.subcriptionPlan = payload
                console.log(state.subcriptionPlan);
                state.loading = false;
                state.success = true;
            })
            .addCase(getSubcriptionPlans.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(checkOutsubcription.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(checkOutsubcription.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(checkOutsubcription.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            

    }
})


export const { resetState,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer