import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../redux-store/stores/store";
import { Button } from "../../ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../../ui/dropdown-menu";

import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../../../redux-store/reducers/auth-reducers";
import { useEffect } from "react";
import { resetState } from "../../../../redux-store/reducers/dashboard-reducer";
import { LogOut, Settings } from "lucide-react";

const User = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch()

    const navigateProfile = () => {
        navigate('/profile')
    };
    const { userInfo, token } = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        if (!userInfo && !token) {
            navigate('/login', { replace: true })
        }
    }, [navigate, userInfo, token])

    const logoutUser = () => {
        dispatch(resetState())
        dispatch(logout())
    }

    return (<DropdownMenu>
        <DropdownMenuTrigger asChild>
            <Button
                variant="outline"
                size="iconLg"
                className="overflow-hidden rounded-full bg-primary text-primary-foreground"
                
            >
                {
                    userInfo?.profilePicture ?
                        <img
                            src={userInfo?.profilePicture}
                            alt="Avatar"
                            className="w-9 h-9 rounded-full object-center"
                        />
                        :
                        <p className="text-lg font-bold uppercase">{userInfo?.userName?.substring(0, 2)}</p>
                }

            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-80 rounded-xl">
            <div className="bg-card rounded-xl">
                <div className="flex flex-col w-full h-30 items-center justify-start">
                    <div className="flex flex-col w-full h-9 items-center justify-center bg-spot-gradient relative rounded-t-xl">
                        <div className="flex rounded-full w-9 h-9 shadow-md items-center justify-center top-3 absolute bg-white">
                            {
                                userInfo?.profilePicture ?
                                    <img
                                        src={userInfo?.profilePicture}
                                        alt="Avatar"
                                        className="w-9 h-9 rounded-full object-center"
                                    />
                                    :
                                    <p className="font-semibold capitalize">{userInfo?.userName?.substring(0, 1)}</p>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col w-full h-11 items-center justify-center py-8 px-2 space-y-1 mt-2">
                        <p className="font-semibold capitalize">{userInfo?.userName}</p>
                        <p className="text-xs secondary">{userInfo?.userEmail}</p>
                    </div>
                </div>
            </div>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={navigateProfile} className="flex space-x-2 items-center" id="s:userprofile-setting_button">
                <Settings />
                <p>Settings</p>
            </DropdownMenuItem>
            {/* <DropdownMenuItem>Support</DropdownMenuItem> */}
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={logoutUser} className="flex space-x-2 items-center" id="s:userprofile-logout_button">
                <LogOut />
                <p>Logout</p>
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>)
}

export default User