'use client';
import { useEffect, useRef, useState } from 'react';
import { cn } from '../../core/lib/utils';
import styles from './pricing.module.css';

import { Button } from '../../core/components/ui/button';
import { checkOutsubcription, getSubcriptionPlans } from '../../shared/services/subcriptionPlan';
import { AppDispatch, RootState } from '../../redux-store/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { subcriptionPlan } from '../../shared/models/subcription-model';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import { RefreshAuthToken } from '../../shared/services';
import { resetSuccess } from '../../redux-store/reducers/dashboard-reducer';
import { useForm } from 'react-hook-form';
import ContactUs from './contacUs';
import { InfoIcon } from 'lucide-react';


export interface PricingTierFrequency {
    id: string;
    value: string;
    label: string;
    priceSuffix: string;
}

export const frequencies: PricingTierFrequency[] = [
    { id: '1', value: '1', label: 'Monthly', priceSuffix: '/month' },
    // { id: '2', value: '2', label: 'Daily', priceSuffix: '/day' },
];


const CheckIcon = ({ className }: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className={cn('w-6 h-6', className)}
        >
            <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export const SubcriptionMethod = () => {
    const [frequency, setFrequency] = useState(frequencies[0]);
    const [open, setOpen] = useState(false);
    const getsubcriptionPlanCalled = useRef(false);
    const dispatch: AppDispatch = useDispatch();
    // const { subcriptionPlan } = useSelector((state: RootState) => state.subcriptionPlan);
    const [tiers, setTiers] = useState<subcriptionPlan[]>([]);
    const { reset } = useForm()
    const navigate = useNavigate();
    const location = useLocation();
    const { success } = useSelector((state: RootState) => state.dashboard)
    // const bannerText = '';


    useEffect(() => {
        if (!getsubcriptionPlanCalled.current) {
            dispatch(getSubcriptionPlans());
            getsubcriptionPlanCalled.current = true;
        }
    }, [dispatch]);

    const refresh = async () => {
        console.log('token refresh 1')
        const refreshed = await RefreshAuthToken();
        console.log('token refresh', refreshed)
        if (refreshed) {
            console.log('token refresh 2')
            dispatch(resetSuccess(false))
            window.location.reload()
        }
    }
    useEffect(() => {
        if (location.pathname.includes('success')) {
            refresh();
            navigate('');
        }
    }, [location.pathname]);

    useEffect(() => {
        if (success) {
            refresh()
        }

    }, [success, reset])



    const handleButtonClick = async (tierId: string, priceId: string | undefined, isTrail: boolean) => {
        try {
            const subcribeId = {
                subcriptionId: tierId,
                priceId: priceId,
                isTrail: isTrail

            }
            const resultAction = await dispatch(checkOutsubcription(subcribeId));
            const response = unwrapResult(resultAction);
            window.open(response.url, '_self');
        } catch (error) {
            console.error('Failed to connect integration:', error);
        }

    };

    // useEffect(() => {
    //     if (subcriptionPlan && subcriptionPlan.length > 0) {
    //         const updatedTiers = subcriptionPlan.map(plan => ({
    //             highlighted: true,
    //             id: plan.id,
    //             info: plan.info,
    //             isPoplular: false,
    //             limitationInfo: plan.limitationInfo,
    //             status: true,
    //             subscriptionId: plan.subscriptionId,
    //             plan: plan.plan,
    //             featured: false
    //         }));
    //         setTiers(updatedTiers);
    //     }
    // }, [subcriptionPlan]);

    const handleDialog = () => {
        console.log('inside dialog box');
        setOpen(!open)
    }

    return (
        <div
            className={cn('flex flex-col bg-image w-full items-center', styles.fancyOverlay)}
        >
            <div className="flex flex-col items-center">
                <div className="mx-auto max-w-4xl px-6 mt-16 lg:px-8 flex flex-col items-center">
                    {/* <div className="w-full lg:w-auto mx-auto max-w-4xl lg:text-center">
                        <h1 className="text-black dark:text-white text-4xl font-semibold max-w-xs sm:max-w-none md:text-6xl !leading-tight">
                            Subcription Plan
                        </h1>

                    </div> */}

                    {/* {bannerText ? (
                        <div className="w-full lg:w-auto flex justify-center my-4">
                            <p className="w-full px-4 py-3 text-xs bg-white-900 text-black dark:bg-white/30 dark:text-white/80 rounded-xl">
                                {bannerText}
                            </p>
                        </div>
                    ) : null}

                    {frequencies.length > 1 ? (
                        <div className="mt-16 flex justify-center">
                            <RadioGroup
                                defaultValue={frequency.value}
                                onValueChange={(value: string) => {
                                    setFrequency(frequencies.find((f) => f.value === value)!);
                                }}
                                className="grid gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 bg-white dark:bg-black ring-1 ring-inset ring-gray-200/30 dark:ring-gray-800"
                                style={{
                                    gridTemplateColumns: `repeat(${frequencies.length}, minmax(0, 1fr))`,
                                }}
                            >
                                <Label className="sr-only">Payment frequency</Label>
                                {frequencies.map((option) => (
                                    <Label
                                        className={cn(
                                            frequency.value === option.value
                                                ? 'bg-blue-500/90 text-white dark:bg-blue-900/70 dark:text-white/70'
                                                : 'bg-transparent text-gray-500 hover:bg-blue-500/10',
                                            'cursor-pointer rounded-full px-2.5 py-2 transition-all',
                                        )}
                                        key={option.value}
                                        htmlFor={option.value}
                                    >
                                        {option.label}

                                        <RadioGroupItem
                                            value={option.value}
                                            id={option.value}
                                            className="hidden"
                                        />
                                    </Label>
                                ))}
                            </RadioGroup>
                        </div>
                    ) : (
                        <div className="mt-12" aria-hidden="true"></div>
                    )} */}

                    <div
                        className={cn(
                            'isolate mx-auto mb-28 grid max-w-md h-[650px] w-[1150px] gap-4 mx-16 m-20  lg:max-w-none',
                            tiers.length === 3 || tiers.length === 2 ? 'lg:grid-cols-3' : '',
                            tiers.length === 5 ? 'lg:grid-cols-3' : '',
                        )}

                    >
                        {tiers.map((tier) => (
                            <div key={tier.id} className={cn(tier.id === 8 ? 'ring-pink-500' : tier.featured ? '!bg-white-900 ring-blue-700  ' : 'ring-blue-500 hover:ring-blue-400', 'max-w-xs ring-2 rounded-[30px] p-8 xl:p-10  hover:cursor-pointer hover:ring-4 hover:shadow-xl flex flex-col shadow shadow-xl shadow-black', tier.highlighted ? styles.fancyGlassContrast : '', 'group')}>
                                <h3 id={tier.id?.toString()}
                                    className={cn(tier.featured ? 'text-blue ' : 'text-white', tier.id === 8 ? 'group-hover:text-pink-400' : 'group-hover:text-blue-400', 'text-3xl font-bold tracking-tight mx-auto')}>
                                    {tier.plan?.name}
                                </h3>
                                <div className={cn(tier.id === 8 ? 'border-t-4 w-[70px] mx-auto border-pink-500 my-2 ' : "border-t-4 w-[70px] mx-auto border-blue-300 my-2")}></div>
                                <p className="mt-6 flex items-baseline gap-x-1">
                                    <span className={cn(tier.featured ? 'text-white ' : 'text-white font-bold text-2xl',)}>
                                        {typeof tier.plan?.subTitle === 'string'
                                            ? tier.plan?.subTitle
                                            : tier.plan?.subTitle[frequency.value]}
                                    </span>
                                </p>
                                {tier.id === 8 && (
                                    <p className='text-xs text-white mt-2'>Charges will apply after 30 day trial period.</p>
                                )}
                                <p className={cn(tier.featured ? 'text-gray-300' : 'text-gray-300 ', 'mt-3 text-sm leading-6',)}>
                                    {tier.info?.description}
                                </p>
                                {/* <span className={cn(tier.featured ? 'text-white ' : 'text-black ', 'text-4xl font-bold tracking-tight', tier.plan?.pricePlans ? 'line-through' : '',)}>
                                        {typeof tier.plan?.subTitle === 'string'
                                            ? tier.plan?.secondaryPrice
                                            : tier.plan?.subTitle[frequency.value]}
                                    </span> */}


                                {/* {typeof tier.plan?.subTitle !== 'string' ? (
                                        <span className={cn(tier.featured ? 'text-gray-300 ' : ' text-gray-600', 'text-sm font-semibold leading-6',)}>
                                            {frequency.priceSuffix}
                                        </span>
                                    ) : null} */}

                                <ul className='mt-8 space-y-1 text-sm leading-2 xl:mt-4'>
                                    {tier.plan?.featureList.map((feature: any) => (
                                        <li key={feature} className="flex gap-x-3 text-gray-300">
                                            <CheckIcon
                                                className={cn(tier.featured ? 'text-white' : '', tier.highlighted ? (tier.id === 8 ? 'text-pink-400' : 'text-blue-400') : 'text-white', 'h-6 w-5 flex-none',)}
                                                aria-hidden="true"
                                            />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                {tier.id === 8 && (
                                    <p className='flex text-xs text-gray-300 mt-6 border rounded-lg p-2'>
                                        <InfoIcon className='w-3 h-3 flex-none mr-1' />
                                        After the 30-day trial, a prepaid annual fee of $1,188
                                        will be automatically billed, covering the entire year
                                        at a rate of $99 per month.
                                    </p>
                                )}
                                <div className="mt-auto">
                                    <div className="relative flex shadow-sm">
                                        <div className="relative w-full">
                                            <Button
                                                size="lg"
                                                className={cn(
                                                    tier.id === 8 ? 'bg-pink-500 w-full' : 'w-full text-white bg-blue-400 hover:opacity-75 transition-opacity'
                                                )}
                                                onClick={tier.id === 9 ? handleDialog : () => handleButtonClick(tier.subscriptionId, tier.plan?.pricePlans[0]?.priceId, tier.id === 8)}
                                            >
                                                {tier.id === 8 ? 'Try Free' : tier.id === 7 ? 'Buy Now' : tier.id === 9 ? 'Contact Us' : 'Get Started'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
                <ContactUs open={open} setOpen={setOpen} />
            </div>
        </div>
    )
}