import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from ".";



export const getSearchCockpit = createAsyncThunk(
    'api/searchCockpit',
    async (_, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.get('Company/search-cockpit');
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )


export const updateCockpit = createAsyncThunk(
    'api/updateCockpit',
    async (cockpitDetails:any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.post('Company/search-cockpit',cockpitDetails);
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )
