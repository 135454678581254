import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '.';



export const categoryPathSend = createAsyncThunk(
  'api/categoryPathSend',
  async (path: string , { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.post('/fileboxes/get-file-folders',{path});
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

