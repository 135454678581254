import { ColumnDef } from "@tanstack/react-table";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card"
import { Button } from "../../../core/components/ui/button";
import { CaretSortIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Input } from "../../../core/components/ui/input";
import { InfoIcon, PencilIcon, SaveIcon, Trash2, X } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../core/components/ui/dropdown-menu";
import { DataTable } from "../../../shared/components/data-table/data-table";
import { PaymentsDetails } from "../../../shared/models/payment-model";
import { useEffect, useRef, useState } from "react";
import { history, subcriptionPlan } from "../../../shared/models/subcription-model";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { useDispatch, useSelector } from "react-redux";
import PaymentPage from "./paymentMethod";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

export const PaymetnsMethods = () => {
    const [open, setOpen] = useState(false);
    const getCardDetails = useRef(false);
    const getPaymentHistory = useRef(false);
    const dispatch: AppDispatch = useDispatch();
    const { paymentHistory, paymentMethod } = useSelector((state: RootState) => state.subcriptionPlan);

    const progress = 85;
    const progress2 = 15;
    const total = 100

    console.log(paymentHistory);
    useEffect(() => {
        if (!getCardDetails.current) {
            // dispatch(getPaymentMethod());
            getCardDetails.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (!getPaymentHistory.current) {
            // dispatch(getHistory());
            getPaymentHistory.current = true;
        }
    }, [dispatch]);


    const handleDialog = () => {
        setOpen(!open)
    }

    // const handleDeleteDialog = () => {
    //    dispatch(deleteSubcription())
    // }



    const handleRemoveFile = (integrationId: number) => {
        // dispatch(deleteIntegration(integrationId));
    };


    return (
        <div>
        </div>
    );
}


export const getColumns = (): ColumnDef<history, any>[] => [
    {
        accessorKey: "periodStarted",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Start Date
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => {
            const dateValue = row.getValue("periodStarted") as string;
            const formattedDate = new Date(dateValue).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            return <div className="lowercase flex ml-10">{formattedDate}</div>;
        },
    },
    {
        accessorKey: "periodEnd",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    End Date
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => {
            const dateValue = row.getValue("periodEnd") as string;
            const formattedDate = new Date(dateValue).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            return <div className="lowercase flex ml-10">{formattedDate}</div>;
        }
    },
    {
        accessorKey: "info", // Use "info" as the accessor key
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Payment Method
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            const info = row.original.info; // Access the entire "info" object
            return (
                <div className="lowercase flex ml-10">
                    {info ? info.brand : "No brand specified"}
                </div>
            );
        },
    },
    {
        accessorKey: "amount",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Amount
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => <div className="lowercase flex ml-10">{row.getValue("amount")}</div>,
    },
    {
        accessorKey: "status",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Status
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => <div className="lowercase flex ml-10">
            <span className="border border-gray-800  bg-green-300 rounded-full px-2">
                {row.getValue("status")}
            </span>
        </div>,
    }
]
