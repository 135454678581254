import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Button } from "../../../core/components/ui/button";
import { Input } from "../../../core/components/ui/input";
import { getuserDetails } from "../../../shared/services/userManage";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { getTeams } from "../../../shared/services/teamService";
import { sharedLinkReq } from "../../../shared/services/sharedLink";
import { toast } from "react-toastify";
import { resetState } from "../../../redux-store/reducers/sharedLink-reducers";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../core/components/ui/tabs";
import { X } from "lucide-react";
import { Label } from "../../../core/components/ui/label";

interface FileDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    files?: any[] | null;
    setFiles?: React.Dispatch<React.SetStateAction<any[] | null>>;
}

const SharedLinkpop: React.FC<FileDialogProps> = ({ open, setOpen }) => {
    const [isPublic, setIsPublic] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([]);
    const [searchUsers, setSearchUsers] = useState('');
    const [searchTeams, setSearchTeams] = useState('');
    const dispatch: AppDispatch = useDispatch();
    const { users } = useSelector((state: RootState) => state.userManage);
    const { teams } = useSelector((state: RootState) => state.teamManage);
    const { selectedChatId } = useSelector((state: RootState) => state.chat);
    const { sharedLink } = useSelector((state: RootState) => state.sharedLink);
    const getTeamDetials = useRef(false);

    useEffect(() => {
        if (!getTeamDetials.current && open) {
            dispatch(getuserDetails());
            dispatch(getTeams());
            dispatch(resetState());
            getTeamDetials.current = true;
        }
    }, [dispatch, open]);

    const handleUserSelection = (userId: number) => {
        setSelectedUserIds((prev) =>
            prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId]
        );
        dispatch(resetState());
        setShowUserSuggestions(false);
    };

    const handleTeamSelection = (teamId: number) => {
        setSelectedTeamIds((prev) =>
            prev.includes(teamId) ? prev.filter((id) => id !== teamId) : [...prev, teamId]
        );
        dispatch(resetState());
        setShowTeamSuggestions(false);
    };

    const createLink = () => {
        const requestBody = {
            chatId: selectedChatId,
            isPublic: isPublic,
            userGroup: selectedTeamIds,
            userIds: selectedUserIds,
        };
        dispatch(sharedLinkReq(requestBody)).then(() => {
            toast.success("Shared link was created successfully!");
        }).catch(err => {
            toast.error("Failed to copy token.");
        });
    };

    const mockLink = 'https://stashify.ai/share/...';

    const [userSuggestions, setUserSuggestions] = useState<any[]>([]);
    const [teamSuggestions, setTeamSuggestions] = useState<any[]>([]);
    const [showUserSuggestions, setShowUserSuggestions] = useState(false);
    const [showTeamSuggestions, setShowTeamSuggestions] = useState(false);

    const userSuggestRef = useRef<HTMLDivElement>(null);
    const teamSuggestRef = useRef<HTMLDivElement>(null);


    const handleUserSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchUsers(value);
        setUserSuggestions(users.filter(user => user.fullName.toLowerCase().includes(value.toLowerCase())));
        setShowUserSuggestions(value.length > 0 && userSuggestions.length > 0);
    };

    const handleTeamSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTeams(value);
        setTeamSuggestions(teams.filter(team => team.groupName.toLowerCase().includes(value.toLowerCase())));
        setShowTeamSuggestions(value.length > 0 && teamSuggestions.length > 0);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (userSuggestRef.current && !userSuggestRef.current.contains(event.target as Node)) {
                setShowUserSuggestions(false);
            }
            if (teamSuggestRef.current && !teamSuggestRef.current.contains(event.target as Node)) {
                setShowTeamSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="sm:max-w-[650px] lg:max-h-[600px] max-h-[90vh] max-w-[90vw] rounded overflow-y-auto">
                    <DialogHeader className="flex flex-col items-left">
                        <DialogTitle className="font-extrabold text-lg">
                            Share Public Link to Chat
                        </DialogTitle>
                        <DialogDescription>
                            Your users, teams you add after sharing stay private.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="w-full h-[1px] bg-gray-400 mt-1"></div>

                    {/* Tabs for Link Type */}
                    <Tabs defaultValue="public"
                        onValueChange={(value) => {
                            if (value === "public") {
                                setIsPublic(true);
                                setSelectedUserIds([]);
                                setSelectedTeamIds([]);
                                dispatch(resetState());
                            } else if (value === "private") {
                                setIsPublic(false);
                                dispatch(resetState());
                            }
                        }}>
                        <TabsList className="bg-transparent p-2 rounded-lg flex space-x-2 justify-centre sm:justify-start w-full">
                            <TabsTrigger
                                value="public"
                                className="px-6 py-1 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
                            >
                                Public
                            </TabsTrigger>
                            <TabsTrigger
                                value="private"
                                className="px-6 py-1 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
                            >
                                Private
                            </TabsTrigger>
                        </TabsList>

                        <TabsContent value="public">
                            {/* Content for Public Tab */}
                            <p className="mt-4 ml-4 text-sm">This link is accessible to everyone.</p>
                        </TabsContent>
                        <TabsContent value="private">
                            {/* Content for Private Tab */}
                            {/* users */}
                            <div className="flex flex-col sm:flex-row mt-4 px-5 gap-4">
                            <div className="sm:w-1/2 sm:pr-4 sm:border-r">
                            <Label className="text-sm font-extrabold">Select Users to Share With</Label>
                            <div className="mb-1" ref={userSuggestRef}>
                                <Input
                                    id="s:chat-sharedLink-search_users-input_field"
                                    placeholder="Search users..."
                                    value={searchUsers}
                                    onChange={handleUserSearchChange}
                                    className="mt-2"
                                />
                                {showUserSuggestions && userSuggestions.length > 0 && (
                                    <div className="border border-gray-300 max-h-40 overflow-y-auto">
                                        {userSuggestions.map(user => (
                                            <div
                                                key={user.userId}
                                                className="cursor-pointer hover:bg-gray-100 p-2"
                                                onClick={() => handleUserSelection(user.userId)}
                                            >
                                                {user.fullName}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                                <div>

                                    {selectedUserIds.length === 0 ? (
                                        <p className="text-sm">No users selected.</p>
                                    ) : (
                                        <div className="flex flex-wrap">
                                            {users.filter(user => selectedUserIds.includes(user.userId)).map(user => (
                                                <div key={user.userId} className="flex items-center border p-1 rounded-3xl m-1 bg-gray-300">
                                                    <span className="pl-2">{user.fullName}</span>
                                                    <button
                                                        className="ml-2 text-red-500"
                                                        onClick={() => handleUserSelection(user.userId)}>
                                                        <X size={16} />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>    
                            
                                {/* teams */}
                            <div className="sm:w-1/2 sm:pr-4">
                            <div className="mb-4">
                                <Label className="text-sm font-extrabold">Select Teams to Share With</Label>
                                <div className="mb-1" ref={teamSuggestRef}>
                                    <Input
                                        id="s:chat-sharedLink-search_teams-input_field"
                                        placeholder="Search teams..."
                                        value={searchTeams}
                                        onChange={handleTeamSearchChange}
                                        className="mt-2"
                                    />
                                    {showTeamSuggestions && teamSuggestions.length > 0 && (
                                        <div className="border border-gray-300 max-h-40 overflow-y-auto">
                                            {teamSuggestions.map(team => (
                                                <div
                                                    key={team.id}
                                                    className="cursor-pointer hover:bg-gray-100 p-2"
                                                    onClick={() => handleTeamSelection(team.id)}
                                                >
                                                    {team.groupName}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                    <div>

                                        {selectedTeamIds.length === 0 ? (
                                            <p className="text-sm ">No teams selected.</p>
                                        ) : (
                                            <div className="flex flex-wrap">
                                                {teams.filter(team => selectedTeamIds.includes(team.id)).map(team => (
                                                    <div key={team.id} className="flex items-center border p-1 rounded-3xl m-1 bg-gray-300">
                                                        <span className="pl-2">{team.groupName}</span>
                                                        <button
                                                            className="ml-2 text-red-500"
                                                            onClick={() => handleTeamSelection(team.id)}>
                                                            <X size={16} />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>    
                                
                            </div>
                        </TabsContent>
                    </Tabs>

                    <div className="text-center flex flex-inline gap-4">
                        <Input
                            id="s:chat-sharedLink-shared_link-input_field"
                            value={sharedLink?.link || mockLink}
                            readOnly
                            className="mt-4"
                        />
                        {sharedLink?.link ? (
                            <Button size='lg'
                                onClick={() => navigator.clipboard.writeText(sharedLink.link)}
                                className="mt-4"
                            >
                                Copy Link
                            </Button>
                        ) : (
                            <Button size='lg' className="mt-4" onClick={() => createLink()}
                                disabled={!isPublic && selectedUserIds.length === 0 && selectedTeamIds.length === 0}
                            >
                                Create Link
                            </Button>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SharedLinkpop;
