import { Button } from "../../core/components/ui/button"
import { Input } from "../../core/components/ui/input"
import { Label } from "../../core/components/ui/label"

import { NavLink, useNavigate } from "react-router-dom"
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../core/components/ui/card"
import { Separator } from "../../core/components/ui/separator"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"

import { loginAction } from '../../shared/services/api'
import { AppDispatch, RootState } from "../../redux-store/stores/store"
import { useEffect, useState } from "react"
import { Checkbox } from "../../core/components/ui/checkbox"

import logo from '../../assets/images/logo.png'


export default function LoginForm() {
    const [hasRedirected, setHasRedirected] = useState(false);
    const { loading, error, userInfo } = useSelector((state: RootState) => state.auth)
    const dispatch: AppDispatch = useDispatch()
    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const navigate = useNavigate()

    useEffect(() => {
        if (userInfo && !hasRedirected) {
            setHasRedirected(true);
            navigate('/home')
        }
    }, [userInfo, navigate, hasRedirected])

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);


    const submitForm = (payload: any) => {
        payload.isRemember = payload.isRemember === 'on' ? true : false
        localStorage.setItem('isRemember', payload.isRemember)
        dispatch(loginAction(payload))
    }

    return (
        <div className="flex flex-col min-h-screen w-full items-center justify-center">
            <div className="flex h-full items-center justify-center">
                <Card className="mx-auto max-w-sm ">
                    <CardHeader className="flex flex-col w-full text-center items-center justify-center">
                        <img src={logo} className="w-[15rem] h-auto" />
                        <CardTitle className="text-2xl uppercase" id="s:signIn-login_mainTopic_label">Login</CardTitle>
                        <CardDescription className="text-primary" id="s:signIn-login_mainDescription_label">
                            Enter your email below to login to your account
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="grid gap-4 space-y-2">
                                <div className="grid gap-2">
                                    <Label htmlFor="email" id="s:signIn-email_label">Email</Label>
                                    <Input className="bg-muted"
                                        id="s:loginForm-email_input"
                                        type="email"
                                        placeholder="m@example.com"
                                        autoFocus
                                        {...register('email', {
                                            required: 'Email is required',
                                            validate: value => value.trim() !== '' || 'Email cannot be only spaces'
                                        })}
                                    />
                                    {errors.email && <p className="text-xs text-red-600">{errors.email.message?.toString()}</p>}
                                </div>
                                <div className="grid gap-2">
                                    <div className="flex items-center">
                                        <Label htmlFor="password" id="s:signIn-password_label">Password</Label>
                                        <NavLink to="/forget-password" onClick={() => {
                                            reset();
                                        }
                                        } className="ml-auto inline-block text-sm underline text-muted-foreground">
                                            Forgot your password?
                                        </NavLink>
                                    </div>
                                    <Input className="bg-muted" id="s:loginForm-password_input" type="password" autoComplete="password" required  {...register('password', {
                                        required: 'Password is required',
                                        validate: value => value.trim() !== '' || 'Password cannot be only spaces'
                                    })} />

                                    {errors.password && <p className="text-xs text-red-600">{errors.password.message?.toString()}</p>}
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="isRemember" defaultChecked {...register('isRemember')} />
                                    <label
                                        htmlFor="isRemember"
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                        id="s:signIn-rememberMe_label"
                                    >
                                        Remember me
                                    </label>
                                </div>
                                <Button type="submit" className="w-full" disabled={loading} id="s:loginForm-logn_button">
                                    {loading ? 'Loading...' : 'Login'}
                                </Button>
                                <Separator />
                                {/* <div className="mt-4 text-center text-sm">- Or -</div>
                            <Button variant="outline" className="w-full">
                                Login with Google
                            </Button>
                            <Button variant="outline" className="w-full">
                                Login with Microsoft
                            </Button> */}

                            </div>
                        </form>
                        <div className="mt-4 text-center text-sm" id="s:signIn-signUp_label">
                            Don&apos;t have an account?{" "}
                            <NavLink to="/sign-up" className="underline">
                                Sign up
                            </NavLink>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}
