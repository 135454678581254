import { createSlice } from '@reduxjs/toolkit';
import { teamUserModel } from '../../shared/models/userManage-model';
import { deleteUser, deleteUserProfile, getuserDetails, restoreUser, teamUserAdd, updateUserdata } from '../../shared/services/userManage';

const initialState: teamUserModel = {
    loading: false,
    error: '',
    users: [],
    success: false,
    resetSuccess: false,
    progress: null,
}


const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getuserDetails.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getuserDetails.fulfilled, (state, { payload }) => {
                state.users = payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getuserDetails.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(teamUserAdd.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(teamUserAdd.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;

                const exists = state.users.some(
                    (keys) => keys.userId === payload.userId
                );

                if (!exists) {
                    const newkeys = {
                        ...payload
                    };
                    state.users?.push(newkeys);
                }
            })
            .addCase(teamUserAdd.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(deleteUser.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(deleteUser.fulfilled, (state, { payload }) => {
                state.users = state.users.map((user) =>
                    user.userId === payload.userId ? { ...user, userStatus: payload.userStatus } : user
                );
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(deleteUserProfile.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(deleteUserProfile.fulfilled, (state, { payload }) => {
                state.users = state.users.map((user) =>
                    user.userId === payload.userId ? { ...user, userStatus: payload.userStatus } : user
                );
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteUserProfile.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(updateUserdata.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(updateUserdata.fulfilled, (state, { payload }) => {
                state.users = state.users.map((user) =>
                    user.userId === payload.userId
                        ? {
                            ...user,
                            fullName: payload.fullName,
                            email: payload.email,
                            role: payload.role,
                            userInfo: {
                                ...user.userInfo,
                                mobileNo: payload.userInfo?.mobileNo || user.userInfo?.mobileNo,
                                jobTitle: payload.userInfo?.jobTitle || user.userInfo?.jobTitle
                            },
                            userStatus: payload.userStatus
                        }
                        : user
                );
                state.loading = false;
                state.success = true;
            })
            .addCase(updateUserdata.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(restoreUser.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(restoreUser.fulfilled, (state, { payload }) => {
                state.users = state.users.map((user) =>
                    user.userId === payload.userId ? { ...user, userStatus: payload.userStatus } : user
                );
                state.loading = false;
                state.success = true;
            })
            .addCase(restoreUser.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

    }
})


export const { resetState,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer