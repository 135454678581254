import { memo } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { Button } from "../../../core/components/ui/button";
import { Copy, Plus, Trash } from "lucide-react";
import { CaretSortIcon } from "@radix-ui/react-icons"
import { ColumnDef } from "@tanstack/react-table"
import { DataTable } from "../../../shared/components/data-table/data-table";
import { createAPIKey, deleteAPIKey, getKeyList } from '../../../shared/services/apiKeys';
import { apiKeys } from '../../../shared/models/apiKey-model';
import { toast } from 'react-toastify';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../../core/components/ui/alert-dialog';

export const ApitKeyManagement = () => {
    const dispatch: AppDispatch = useDispatch()
    const { apiKeys } = useSelector((state: RootState) => state.apiKeyManage);
    const getApiKeyList = useRef(false);



    useEffect(() => {
        if (!getApiKeyList.current) {
            dispatch(getKeyList());
            getApiKeyList.current = true;
        }
    }, [dispatch]);

    const handleDialog = () => {
        dispatch(createAPIKey())
    }

    const handleDeleteFile = (value: any) => {
        dispatch(deleteAPIKey(value));
    }
    const handleCopyFile = (token: any) => {
        navigator.clipboard.writeText(token).then(() => {
            toast.success("Token copied to clipboard!")
        }).catch(err => {
            toast.error("Failed to copy token.");
        });
    }

    return (
        <div className="flex flex-row w-full overflow-auto gap-6 p-6  h-100vh">
            <Card className="w-full h-fit">
                <CardHeader>
                    <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
                        <p className="text-xl font-bold mb-2">API Keys </p>
                        <Button size={"lg"} type="submit" onClick={handleDialog} className="space-x-2" id="s:setting-apikey-create_api_button">
                            <Plus className="size-5" /> <p className="hidden md:flex">Create New API Key</p>
                        </Button>
                    </CardTitle>
                    <CardDescription>
                        <span className="flex flex-row w-full items-center justify-between">
                            <span className="">
                                Manage API Keys
                            </span>
                        </span>
                    </CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col justify-center items-center">
                    <DataTable
                        data={apiKeys}
                        getColumns={() => getColumns(handleDeleteFile, handleCopyFile)}
                    />
                </CardContent>
            </Card>
        </div >
    );
}

export const getColumns =
    (
        handleDeleteFile: (value: any) => void,
        handleCopyFile: (value: any) => void
    ): ColumnDef<apiKeys, any>[] => [
            {
                accessorKey: "name",
                header: ({ column }) => (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Name
                        <CaretSortIcon className="ml-2 h-4 w-4" />
                    </Button>
                ),
                cell: memo(({ row }) => (
                    <div className="flex ml-10">{row.getValue("name")}</div>
                )),
            },
            {
                accessorKey: "token",
                header: ({ column }) => (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        API Keys
                        <CaretSortIcon className="ml-2 h-4 w-4" />
                    </Button>
                ),
                cell: memo(({ row }) => (
                    <div className="flex ml-10">{row.getValue("token")}</div>

                )),
            },
            {
                id: "actions",
                enableHiding: false,
                cell: memo(({ row }) => {
                    const apiKeysData = row.original;
                    return (
                        <div className="flex flex-row items-center justify-end space-x-2 mr-6">
                            <Button size={"icon"} variant="outline" className="border-green-500 rounded-full" onClick={() => handleCopyFile(apiKeysData.token)} id="s:apiKey-table-copy-button">
                                <Copy className="size-5" />
                            </Button>
                            <ConfirmDeleteButton onConfirm={() => handleDeleteFile(apiKeysData.id)} />
                        </div>
                    );
                }),
            },
        ]
const ConfirmDeleteButton = ({ onConfirm }: { onConfirm: () => void }) => {
    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button size={"icon"} variant="outline" className="border-secondary rounded-full mr-5" id="s:apiKey-table-delete-button">
                    <Trash className="size-5" />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Delete ApiKey</AlertDialogTitle>
                    <AlertDialogDescription>
                    Are you sure you want to delete this API key?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel> <Button variant="ghost" id="s:apiKey-table-delete_cancel-button">Cancel</Button></AlertDialogCancel>
                    <AlertDialogAction> <Button variant="default" onClick={onConfirm} id="s:apiKey-table-delete_confirm-button">Delete</Button></AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}

