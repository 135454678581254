import React, { memo, useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../../core/components/ui/card';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Button } from '../../../core/components/ui/button';
import { DataTable } from '../../../shared/components/data-table/data-table';
import { Plus, Trash, PencilIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import { Input } from '../../../core/components/ui/input';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../../core/components/ui/alert-dialog';
import { Label } from '../../../core/components/ui/label';
import { getCategoryConfig, saveCategoryConfig, deleteCategoryConfig } from '../../../shared/services/categoryConfig';
import { AppDispatch, RootState } from '../../../redux-store/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnDef } from '@tanstack/react-table';
import { Categories } from '../../../shared/models/categoryConfig-model';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../core/components/ui/select';
import { getConnectedDrive } from '../../../shared/services/userSetting';
import { Textarea } from '../../../core/components/ui/textarea';

const CategoryConfig = () => {
  // const [Categories, setCategories] = useState<any[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<any>(null);
  const [title, setTitle] = useState<string>('');
  const [folders, setFolders] = useState<{ folder: string | null, integration: string | null }[]>([{ folder: '', integration: null }]);
  const [prompt, setPrompt] = useState<string>('');
  //const [json, setJson] = useState<string>('');
  const getCatogeryCalled = useRef(false);
  const dispatch: AppDispatch = useDispatch();
  const { categories } = useSelector((state: RootState) => state.categories);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isCollate, setCollate] = useState(false);
  const { integrationList } = useSelector((state: RootState) => state.userSetting);

  useEffect(() => {
    if (!getCatogeryCalled.current) {
      dispatch(getCategoryConfig())
      dispatch(getConnectedDrive());
      getCatogeryCalled.current = true;
    }
  }, [dispatch])

  // useEffect(() => {
  //   if (categories) {
  //     setCategories(categories);
  //   }
  // }, [categories]);

  const openAddDialog = () => {
    resetForm();
    setIsEditing(false);
    setIsDialogOpen(true);
  };

  const openEditDialog = (category: any) => {
    setTitle(category.title);
    setFolders(category.categoryFolders);
    setPrompt(category.prompt);
    //setJson(category.json);
    setCurrentCategory(category);
    setIsEditing(true);
    setIsDialogOpen(true);
    setCollate(category.isCollate);
  };

  const resetForm = () => {
    setTitle('');
    setFolders([{ folder: '', integration: null }]);
    setPrompt('');
    //setJson('');
    //setCurrentCategory(null);
  };

  const handleSave = () => {
    if (isEditing && currentCategory) {
      // Update category
      const updatedCategory = { ...currentCategory, title, categoryFolders: folders,prompt, isCollate };
      // setCategories(prev => prev.map(cat => (cat.id === currentCategory.id ? updatedCategory : cat)));

      dispatch(saveCategoryConfig(updatedCategory))
        .then(() => {
          toast.success('Category updated successfully');
        })
        .catch((error) => {
          toast.error('Error updating category', error);
        });

    } else {
      // Add new category
      const newCategory = { title, categoryFolders: folders, prompt, isCollate };
      //setCategories(prev => [...prev, newCategory]);
      dispatch(saveCategoryConfig(newCategory))
        .then(() => {
          toast.success("Category added successfully");
        })
        .catch((error) => {
          toast.error('Error Category Added', error);
        });
    }
    setIsDialogOpen(false);
  };

  const handleDelete = (categoryId: number) => {
    dispatch(deleteCategoryConfig(categoryId))
      .then(() => {
        // setCategories(prev => prev.filter(cat => cat.id !== categoryId));
        toast.success('Category deleted successfully');
      })
      .catch((error) => {
        toast.error('Error deleting category: ' + error);
      });
  };

  useEffect(() => {
    const isTitleAndFoldersFilled = title.trim() !== '' && folders.some(folder => folder.folder?.trim() !== '');
    
    if (isCollate) {
      setIsSaveButtonDisabled(!(isTitleAndFoldersFilled && prompt.trim() !== ''));
    } else {
      setIsSaveButtonDisabled(!isTitleAndFoldersFilled);
    }
  }, [title, folders, isCollate, prompt]);

  const addFolder = () => setFolders([...folders ?? [], { folder: '', integration: null }]);

  const updateFolder = (index: number, value: string) => {
    setFolders((prev) => {
      const updatedFolders = prev.map((item, idx) =>
        idx === index ? { ...item, folder: value } : { ...item }
      );
      return updatedFolders
    });
  };

  const getColumns = (
    handleDelete: (value: any) => void,
    openEditDialog: (value: any) => void
  ): ColumnDef<Categories, any>[] => [
      {
        accessorKey: "id",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            ID
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <div className="flex ml-10">{row.getValue("id")}</div>
        )),
      },
      {
        accessorKey: "title",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Title
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <div className="flex ml-10">{row.getValue("title")}</div>
        )),
      },
      {
        id: 'actions',
        cell: ({ row }: any) => {
          const Categories = row.original;
          return (
            <div className="flex flex-row items-center justify-end space-x-2 mr-6">
              <Button variant="outline" size="icon" onClick={() => openEditDialog(Categories)} className="border-blue-500 rounded-full" id="s:categoryConfig-table-edit_button">
                <PencilIcon className="size-5" color='black' />
              </Button>
              <ConfirmDeleteButton onConfirm={() => handleDelete(Categories.id)} />
            </div>
          );
        },
      },
    ];

  const removeFolder = (index: number) => {
    if (folders.length > 1) {
      const updatedFolders = folders.filter((_, i) => i !== index);
      setFolders(updatedFolders);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollate(e.target.checked);
  };

  const handleIntegrationChange = (index: number, value: string) => {

    setFolders((prev) => {
      const updatedFolders = prev.map((folder, idx) =>
        idx === index ? { ...folder, integration: value } : { ...folder }
      );
      return updatedFolders
    });
  }

  return (
    <div className="flex flex-row w-full overflow-auto gap-6 p-6 h-100vh">
      <Card className="w-full h-fit">
        <CardHeader>
          <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
            <p className="text-xl font-bold mb-2">Category Configuration</p>
            <Button size="lg" onClick={openAddDialog} className="space-x-2">
              <Plus className="size-5" /> <p className="hidden md:flex" id="s:categoryConfig-addNewCategory_button">Add New Category</p>
            </Button>
          </CardTitle>
          <CardDescription>Manage Categories</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col justify-center items-center">
          <DataTable data={categories} getColumns={() => getColumns(handleDelete, openEditDialog)} />
        </CardContent>
      </Card>

      {/* Add/Edit Category Dialog */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="w-full max-w-[90%] sm:max-w-[600px] p-4 sm:p-6 rounded-md bg-white max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{isEditing ? 'Edit Category' : 'Add New Category'}</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <Label className="block text-sm font-medium">Title</Label>
            <Input
              id="s:categoryConfig-title-input_field"
              value={title}
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter title"
            />
            <Label className="block text-sm font-medium">Folders</Label>
            {folders?.map((folder, index) => (
              <div key={index} className="flex items-center space-x-2 mb-2">
                <Select
                  value={folder?.integration?.toString()}
                  onValueChange={(item) => handleIntegrationChange(index, item)}
                >
                  <SelectTrigger className="md:min-w-[280px] rounded-2xl">
                    <SelectValue>{integrationList.find(ele => ele.id.toString() === folder.integration?.toString())?.name || "Select a Intergration"}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {integrationList && integrationList.length > 0 ? (
                      integrationList.map((item, indext) =>
                        <SelectItem key={indext} value={item.id.toString()}>{item.name}</SelectItem>
                      )
                    ) : (
                      <li>No integration available</li>
                    )}
                  </SelectContent>
                </Select>
                <Input
                  id="s:categoryConfig-folderName-input_field"
                  value={String(folder.folder)}
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => updateFolder(index, e.target.value)}
                  placeholder="Folder name"
                />
                {folders?.length > 1 && (
                  <Button
                    id="s:categoryConfig-form-newFolder-delete_button"
                    size="icon"
                    variant="outline"
                    onClick={() => removeFolder(index)}
                    className="border-red-500 p-2 rounded-full"
                  >
                    <Trash className="size-5" />
                  </Button>
                )}
              </div>
            ))}

            <Button size="sm" onClick={addFolder} className="space-x-2 mt-2" id="s:categoryConfig-form-newFolder_button">
              <Plus className="size-5" /> <p className="hidden md:flex">New Folder</p>
            </Button>

            <div>
              <div className="flex flex-row gap-2">
                <input
                  type="checkbox"
                  id="collate"
                  className="block"
                  checked={isCollate}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="collate" className="block text-sm font-medium">Collate</label>
              </div>

              {/* Conditionally render the Input field if collate is true */}
              {isCollate && (
                <Textarea
                  id="prompt"
                  value={prompt}
                  className="mt-4 block w-full p-2 border border-gray-300 rounded min-h-[100px] h-auto"
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Enter Your Prompt"
                />
              )}
            </div>
            {/* <Label className="block text-sm font-medium">JSON</Label>
            <Input
              id="JSON"
              value={json}
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              onChange={(e) => setJson(e.target.value)}
              placeholder="Enter JSON"
            /> */}
          </div>
          <div className="flex justify-end mt-4">
            <Button size='lg' onClick={handleSave}  disabled={isSaveButtonDisabled} id="s:categoryConfig-form-update/save_button">
              {isEditing ? 'Update' : 'Save'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ConfirmDeleteButton = ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button size="icon" variant="outline" className="border-secondary rounded-full" id="s:categoryConfig-table-delete_button">
          <Trash className="size-5" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Category</AlertDialogTitle>
          <AlertDialogDescription>Are you sure you want to delete this category?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <Button variant="ghost" id="s:categoryConfig-table-delete-cancel_button">Cancel</Button>
          </AlertDialogCancel>
          <AlertDialogAction>
            <Button variant="default" onClick={onConfirm} id="s:categoryConfig-table-delete-confirm_button">Delete</Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CategoryConfig;
